import React, { ReactElement } from "react";
import MainBanner from "@components/debtreliefoasis/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import DebtReliefOasisWrapper from "@components/layouts/debtReliefOasisWrapper";
import OffersList from "@components/shared/offersList";
import DebtFreePath from "@components/debtreliefoasis/debtFreePath";
import FinancialResiliencePath from "@components/debtreliefoasis/financialResiliencePath";
import QualitiesCompany from "@components/debtreliefoasis/qualitiesCompany";
import Benefits from "@components/debtreliefoasis/benefits";
export default function DebtReliefOasisHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <DebtReliefOasisWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <DebtFreePath />
            <FinancialResiliencePath />
            <QualitiesCompany />
            <Benefits />
        </DebtReliefOasisWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
